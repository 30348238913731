import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import TitleAndDesc from '../../components/TitleAndDesc/TitleAndDesc'
import Modal from './Modal'
import Button from '../../components/Button'
import Table from '../../components/Table'
import Icon from '../../components/Icon'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Confirmation from '../../components/Confirmation'
import { useDispatch, useSelector } from 'react-redux'
import { BiSolidTrashAlt } from 'react-icons/bi'
import { getProducts, setPage, uploadProducts, deleteProduct } from '../../actions/products'
import { Pagination } from '../../components/Pagination'
import { getCompanies } from '../../actions/companies'

const Index = () => {

    const dispatch = useDispatch()

    const [modal, setModal] = useState(false)
    const {list, list_loading, pageNumber, pageCount} = useSelector(state => state.products)
    const {list : companies} = useSelector(state => state.companies)
    const {itemsOnPage} = useSelector(state => state.auth)
    const [company, setCompany] = useState(null)
    const [file, setFile] = useState(null)
    const [confirmation, setConfirmation] = useState(null);
    const [search, setSearch] = useState('');


    useEffect(() => {
            dispatch(getProducts(company?.value || null, search))
      }, [pageNumber, company, itemsOnPage, search])
    
      useEffect(() => {
          dispatch(getCompanies())
      }, [])

    useEffect(() => {
        pageClick({selected: 0})
      }, [itemsOnPage, company, search])

    const pageClick = ({selected}) => dispatch(setPage(selected))

    useEffect(() => {
        file && handleDownload()
    }, [file])
    
    const handleDownload = () => {
        dispatch(uploadProducts(company?.value, file))
    }

    const acceptConfirmation = () => {
        dispatch(deleteProduct(confirmation.id, company?.value))
        setConfirmation(null)
    }

    return (
        <div className={styles.wrapper}>

            {confirmation && <Confirmation
                confirmation={confirmation.name}
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={() => setConfirmation(null)}/>}

            <TitleAndDesc title='Товары'/>

            <Modal modal={modal} setModal={setModal} companies={companies} search={search} />

            <div className={styles.buttons}>
                <div className={styles.selects}>
                <Select 
                    list={companies.map(el => ({value: el.id, label: el.name}))} 
                    setState={setCompany} 
                    state={company} 
                    placeholder='Компания'
                    maxWidth='400px'
                    />
                    <Input propsstyle={{padding:'0 .25rem', width: '400px', marginTop: '1rem'}}  placeholder='Название/SKU' type='text' value={search} onChange={(e) => setSearch(e.target.value)}/>
                    {company?.value && 
                        <div className={styles.buttonWrapper}>
                            <input className={styles.inputFile} type='file' onChange={(e) => setFile(e.target.files[0])}/>
                            <Button mr='0 .5rem' text='Загрузить .xlsx' type='primary' />

                        </div>}
                </div>
                
                <Button mr='2rem 0 0 0' text='Добавить' type='primary' onClick={() => setModal('ADD')}/>
            </div>

            <Pagination
                pageCount={pageCount}
                pageNumber={pageNumber}
                onPageChange={pageClick}>

            <Table isEmpty={list} isLoad={list_loading}>
                <thead>
                    <tr>
                        <th>sku</th>
                        <th>Название</th>
                        <th>Компания</th>
                        <th>Категория</th>
                        <th>Цена</th>
                        <th>Запас</th>
                        <th>Валюта</th>
                        <th style={{width: '120px'}}>Удалить</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map(el => 
                    <tr key={el.id}>
                        <td onClick={() => setModal(el)}>{el.sku}</td>
                        <td onClick={() => setModal(el)}>{el.name}</td>
                        <td onClick={() => setModal(el)}>{companies.find(comp => comp.id === el.companyid)?.name}</td>
                        <td onClick={() => setModal(el)}>{el.category_name}</td>
                        <td onClick={() => setModal(el)}>{el.price}</td>
                        <td onClick={() => setModal(el)}>{el.attributes?.actual_available_stock}</td>
                        <td onClick={() => setModal(el)}>{el.currency}</td>
                        <td
                            onClick={() => setConfirmation({id: el.id, name: el.attributes?.item_name})}
                            key={el.id}
                            style={{width: '120px'}}
                            title='Удалить пользователя'>
                            <Icon color='red'><BiSolidTrashAlt/></Icon>
                        </td>
                    </tr>)}
                </tbody>
            </Table>

            </Pagination>
        </div>
    )
}

export default Index