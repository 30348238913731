import React, {useEffect, useState} from 'react'
import styles from './index.module.css'
import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import {InputFile} from '../../../components/InputFile/InputFile'
import Confirmation from '../../../components/Confirmation'
import Select from '../../../components/Select'
import {useDispatch, useSelector} from 'react-redux'
import {deleteImage, getImageList, getProduct, updateProduct, uploadImage, createProduct} from '../../../actions/products'
import Loader from '../../../components/Loader'
import { BACKEND_URL_PREFIX } from '../../../assets/const'
import { IoMdTrash } from 'react-icons/io'
import { getCategories } from '../../../actions/categories'
import { createNotification } from '../../../components/Notifications/Notifications'

const Index = ({modal, setModal, companies, search}) => {

    const dispatch = useDispatch()
    const {item, item_loading, images = '', images_loading} = useSelector(state => state.products)
    const {list : category, list_loading : category_loading} = useSelector(state => state.categories)

    const [values, setValues] = useState(modal.id)
    const [imageUrl, setImageUrl] = useState('')
    const [images_, setImage_] = useState('')
    const [confirmation, setConfirmation] = useState(null);

    useEffect(() => {
        setImage_(images)
    }, [images])

    useEffect(() => {
            dispatch(getCategories())
    }, [])
    const handleChange = (e, name_) => {
        const {name, value} = e.target
        let copy = {...values}

        if (name_) {
            copy[name_] = { 
                ...copy[name_], 
                [name]: value }
        } else {
            copy[name] = value
        }
        setValues(copy)
    }

    useEffect(() => {
        if (modal
            ?.id) {
            dispatch(getProduct(modal.id))
            dispatch(getImageList(modal.id))
        } else {
            setValues({
                sku: '',
                price: '',
                category: '',
                currency: '',
                name: '',
                attributes: {
                    actual_available_stock: ''
                }
            })
        }
    }, [modal])

    useEffect(() => {
        if (item) {
            setValues(item)
        }
    }, [item])

    const handleSubmit = () => {
        if (modal.id) {
            if (values?.name && 
                values?.companyid && 
                values?.category && 
                values?.price && 
                values?.currency && 
                values?.sku) {
                    dispatch(updateProduct(values, images_))
                    imageUrl && dispatch(uploadImage(item.id, { file: imageUrl.file }))
                    handleClose()
                } else {
                    createNotification('error', 'Заполните все обязательные поля')
                }
        } else {
            addProduct(values, { file: imageUrl.file })
        }
    }

    const handleUploadImage = (e) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files).map(file => ({ file, url: URL.createObjectURL(file) }))
            setImageUrl(filesArray[0])
        }
      };
    
      const addProduct = async (values, images) => {
        if (values?.name && 
            values?.companyid && 
            values?.category && 
            values?.price && 
            values?.currency && 
            values?.sku) {
          await dispatch(createProduct(values, images))
        } else {
            createNotification('error', 'Заполните все обязательные поля')
        }
      };

      const getImage = () => {
        if (imageUrl.url && imageUrl.url.length > 0) {
            return imageUrl.url
        } else {
            return `${BACKEND_URL_PREFIX}/item/${modal?.id}/image`
        }
      }

      const handleClose = () => {
        setImageUrl('')
        setModal(false)
      }

      const handleDeleteImage = () => {
          if (images_) {
              dispatch(deleteImage(modal.id))
            } else {
                setImageUrl('')
          }
      }

      const acceptConfirmation = () => {
        handleDeleteImage(confirmation.id)
        setConfirmation(null)
      }


    return (
        <div className={styles.wrapper}>

            {confirmation && <Confirmation
                confirmation={confirmation.text}
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={() => setConfirmation(null)}/>}

            <Modal
                open={modal}
                width={1000}
                onClose={() => handleClose()}
                title='продукта'
                loading={item_loading}>
                <div
                    className={styles.row}
                    style={{
                    width: '100%'
                }}>
                    <div className={styles.col}>
                        <Input
                            onChange={handleChange}
                            name='name'
                            type="text"
                            placeholder="Название"
                            isRequired
                            value={values?.name}/>
                    </div>
                    <div className={styles.col}>
                        <Select
                            width='auto'
                            list={companies.map(el => ({value: el.id, label: el.name}))}
                            setState={e => handleChange( {target: {name: 'companyid', value: e?.value}})}
                            name='companyid'
                            isRequired
                            placeholder="Компания"
                            state={{value: values?.companyid, label: companies.find(el => el.id == values?.companyid)?.name}}/>
                    </div>
                </div>
                <div
                    className={styles.row}
                    style={{
                    width: '100%'
                }}>
                    <div className={styles.col}>
                        <Input
                            onChange={handleChange}
                            name='sku'
                            type="text"
                            placeholder="sku"
                            isRequired
                            value={values
                            ?.sku}/>
                    </div>
                    <div className={styles.col}>
                        <Select
                            width='auto'
                            list={category?.map(el => ({value: el.id, label: el.category_name}))}
                            setState={e => handleChange( {target: {name: 'category', value: e?.value}})}
                            name='category'
                            isRequired
                            placeholder="Категория"
                            state={{value: values?.category, label: category?.find(el => el.id == values?.category)?.category_name}}
                            />
                    </div>
                    </div>
                    <div className={styles.row}>
                    <div className={styles.col}>
                    <Input
                            onChange={handleChange}
                            name='price'
                            type="text"
                            isRequired
                            placeholder="Цена"
                            value={values
                            ?.price}/>
                    </div>
                    <div className={styles.col}>
                        <Input
                            onChange={(e) => handleChange(e, 'attributes')}
                            name='actual_available_stock'
                            type="text"
                            placeholder="Запас"
                            value={values
                            ?.attributes
                                ?.actual_available_stock}/>
                            </div>
                            <div className={styles.col}>
                        <Input
                            onChange={handleChange}
                            name='currency'
                            type="text"
                            placeholder="Валюта"
                            isRequired
                            value={values
                            ?.currency}/>
                    </div>
                </div>

                <div className={styles.row}>
                {
                    !images_loading ? 
                    <>
                    <div className={styles.col}>
                        
                        <div className={styles.wrapperImg}>
                        {((images_ || imageUrl)) && 
                        <i 
                        onClick={() => setConfirmation({ id: modal.id, text: 'Удалить изображение' })}
                        className={styles.delete}><IoMdTrash/></i>}
                        {(images_ || imageUrl) && <img src={getImage()} alt=''/>}
                        <div className={styles.wrapperBut}>
                            {images_ == false &&
                                <InputFile
                                    multiple
                                    title="Добавить изображение"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) => handleUploadImage(e)}/>
                            }
                        </div>
                        </div>
                    </div>
                    </>
                    :
                    <div className={styles.load}><Loader top="30px" left="40%"/></div>
                  }
                </div>

                <div className={styles.btns}>
                    <Button text='Закрыть' onClick={() => setModal(false)} type='empty'/>
                    <Button text='Сохранить' onClick={handleSubmit}/>
                </div>
            </Modal>
        </div>
    )
}

export default Index