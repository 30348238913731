import React from 'react'
import styles from './index.module.css'

const Index = ({
    propsstyle = {},
    isRequired = false,
    ...props
}) => {
    return (
        <div className={styles.wrapper} style={{...propsstyle}}>
            <span className={styles.placeholder}>{props.placeholder}</span>
            <input 
                className={styles.input} 
                style={{boxShadow: isRequired && (!props.value || props.value == null || props.value === '') && 'var(--light_shadow), 0 0 0 2px red'}}
                {...props}/>
        </div>
    )
}

export default Index