import React from 'react'
import styles from './index.module.css'
import Select from 'react-select'

const Index = ({list, state, setState, maxWidth, width = '100%', placeholder, isRequired}) => {
  return (
        <div
            className={styles.wrapper} 
            style={{maxWidth: maxWidth, width: width}}>
            <span className={styles.placeholder}>{placeholder}</span>
            <div className={isRequired && !state?.value ? styles.error : null}>
            <Select
                options={list}
                placeholder="Выберите компанию"
                className={styles.select}
                isSearchable
                isClearable
                value={state}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(e) => setState(e)}
                noOptionsMessage={({ inputValue }) => `По запросу '${inputValue}' ничего не найдено`}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    boxShadow: `var(--light_shadow)`,
                    border: `1px solid var(--light_grey_color)`,
                    backgroundColor: "#fff",
                    cursor: `pointer`,
                  })
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: `var(--radii)`,
                  colors: {
                    ...theme.colors,
                    //after select dropdown option
                    primary50: `var(--light_grey_color)`,
                    //Border and Background dropdown color
                    primary: `var(--main_color)`,
                    //Background hover dropdown color
                    primary25: `var(--light_grey_color)`,
                    //Background color
                    neutral0: "#fff",
                    //Border before select
                    neutral20: `var(--light_grey_color)`,
                    //Hover border
                    neutral30: `var(--main_color)`,
                    //No options color
                    neutral40: `var(--light_grey_color)`,
                    //Select color
                    // neutral50: `var(--main_color)`,
                    //arrow icon when click select
                    neutral60: `var(--main_color)`,
                    //Text color
                    neutral80: `#000`,
                  },
                })}
            />
            </div>
        </div>
  )
}

export default Index